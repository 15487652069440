<template>
    <div class="col-6" v-if="option.input_type === 'multi_select'">
      <hr/>
      <div class="row mb-1">
        <div :class="`col-12 col-md-${option.additional_field ? 5 : 7}`">
          <label for="">{{ option.name }}{{ option.required ? '*' : null }}</label>
          <v-select
            v-model="form.option"
            :clearable="false"
            :options="options"
            @input="checklist"
            label="name"
          />
        </div>
        <div class="col-12 col-md-3" v-if="option.additional_field">
          <label for="">{{ option.additional_field }}</label>
          <input
            v-model="form.description"
            :placeholder="`100`"
            class="form-control"
          >
        </div>
        <div :class="`col-12 col-md-${option.additional_field ? 4 : 5}`">
          <label for="" class="pt-1"></label>
          <button @click="addSelectedOption" class="btn btn-primary btn-block">Добавить</button>
        </div>
      </div>
      <div class="position-relative table-responsive">
        <table class="table b-table">
          <thead>
          <tr>
            <th>#</th>
            <th>Название</th>
            <th v-if="option.additional_field">{{ option.additional_field }}</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="item in selectedOptions" :key="item.option.id">
              <td>{{ item.option.id }}</td>
              <td>{{ item.option.name }}</td>
              <td v-if="option.additional_field">{{ item.description }}</td>
              <td>
                <button class="btn btn-outline-primary btn-block" @click="deleteSelectedOption(item.option.id)">
                  Удалить
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr/>
    </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'MultiSelect',
  components: { vSelect },
  props: ['option', 'initValues'],
  data() {
    return {
      form: {
        option: null,
        description: null,
      },
      options: [],
      selectedOptions: [],
    };
  },
  mounted() {
    this.options = this.option.values;

    if (this.initValues) {
      this.selectedOptions = this.initValues;
      this.$emit('refresh', this.selectedOptions, this.option.id);
    }
  },
  methods: {
    async checklist() {
      if (this.form.option && this.form.option.id === 'all') {
        this.form.option = null;
        this.options = (await this.$api.options.values(this.option.id)).data;
      }
    },
    addSelectedOption() {
      if (!this.form.option) {
        this.$toast.error(`Выберите ${this.option.name}`);
        return;
      }

      if (this.selectedOptions.length > 0 && this.selectedOptions.find((option) => option.option.id === this.form.option.id)) {
        return;
      }

      this.selectedOptions.push(this.form);
      this.form = {
        option: null,
        description: null,
      };
      this.$emit('refresh', this.selectedOptions, this.option.id);
    },
    deleteSelectedOption(id) {
      this.selectedOptions = this.selectedOptions.filter((option) => option.option.id !== id);
      this.$emit('refresh', this.selectedOptions, this.option.id);
    },
  },
};
</script>

<style scoped>

</style>
