<template>
  <section>
    <div class="row">
      <template v-for="option in productOptions">
        <div class="col-3" :key="option.id" v-if="option.input_type === 'input'">
          <Input
              @refresh="updateOption"
              :option="option"
              :initValue="initInputDictionaries"
          />
        </div>
      </template>
      <template v-for="option in productOptions">
        <div class="col-3" :key="option.id" v-if="option.input_type === 'select'">
          <Select
              @refresh="updateOption"
              :initValues="initSelectDictionaries"
              :option="option"
          />
        </div>
      </template>
    </div>
    <div class="row mt-1">
      <MultiSelect
          v-for="option in multiSelectOptions"
          :initValues="initMultiselectDictionaries[option.id]"
          @refresh="updateOption"
          :option="option"
          :key="option.id"
      />
    </div>
  </section>
</template>

<script>
import MultiSelect from '@/views/components/MultiSelect.vue';
import Select from '@/views/components/Select.vue';
import Input from '@/views/components/Input.vue';

export default {
  name: 'ProductOptionsForm',
  components: { MultiSelect, Select, Input },
  props: ['categoryId', 'initCategoryId', 'dictionaries'],
  data() {
    return {
      form: {},
      productOptions: [],
      multiSelectOptions: [],
      selectedOption: {},
      options: [],
      initInputDictionaries: null,
      initSelectDictionaries: [],
      initMultiselectDictionaries: {},
    };
  },
  watch: {
    async categoryId(newVal) {
      if (newVal) {
        this.productOptions = (await this.$api.categories.productOptions(this.categoryId)).data;
        this.multiSelectOptions = this.productOptions.filter((option) => option.input_type === 'multi_select');
        this.$emit('refresh', this.form);
      }
    },
  },
  async mounted() {
    if (this.initCategoryId) {
      await this.getDictionaries();
    }

    if (this.dictionaries) {
      this.getInputInitOptions();
      this.getSelectInitOptions();
      this.getMultiselectInitOptions();
    }
  },
  methods: {
    async getDictionaries() {
      this.productOptions = (await this.$api.categories.productOptions(this.categoryId)).data;
      this.setDefaults();
      this.multiSelectOptions = this.productOptions.filter((option) => option.input_type === 'multi_select');
    },
    getSelectInitOptions() {
      this.initSelectDictionaries = this.dictionaries.filter((dictionary) => dictionary.product_option
          && dictionary.product_option.input_type === 'select');
    },
    getInputInitOptions() {
      this.initInputDictionaries = this.dictionaries.filter((dictionary) => dictionary.product_option
          && dictionary.product_option.input_type === 'input');
    },
    getMultiselectInitOptions() {
      const productDictionaries = this.dictionaries.filter((dictionary) => dictionary.product_option
          && dictionary.product_option.input_type === 'multi_select');

      productDictionaries.forEach((dictionary) => {
        if (!(dictionary.product_option.id in this.initMultiselectDictionaries)) {
          this.initMultiselectDictionaries[dictionary.product_option.id] = [];
        }
        this.initMultiselectDictionaries[dictionary.product_option.id].push({
          option: dictionary.dictionary,
          description: dictionary.description,
        });
      });
    },
    setDefaults() {
      this.productOptions.forEach((option) => {
        if (option.input_type === 'multi_select') {
          this.form[option.id] = [];
        }
        this.selectedOption[option.id] = {
          option: null,
          description: null,
        };
      });
      this.$emit('refresh', this.form);
    },
    updateOption(form, optionId) {
      this.form[optionId] = form;
      this.$emit('refresh', this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
table > thead > tr > th {
  font-size: 0.6rem;
}
</style>
